<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">售后订单</h3>
    </div>
    <div class="box">
      <div class="condition">
        <div class="time">
          <span class="orderTime">下单时间: </span>
          <el-date-picker
            v-model="TimeValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
            size="small"
          >
          </el-date-picker>
          <span class="applyTime">退款审核时间: </span>
          <el-date-picker
            v-model="applyTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
            size="small"
          >
          </el-date-picker>
          <span class="applyTime">申请时间: </span>
          <el-date-picker
            v-model="startTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="left"
            size="small"
          >
          </el-date-picker>
          <!-- <div class="check">
            <span>订单状态: </span>
            <el-radio v-model="form.type" :label="1">未支付</el-radio>
            <el-radio v-model="form.type" :label="2">待自提</el-radio>
            <el-radio v-model="form.type" :label="3">已自提</el-radio>
            <el-radio v-model="form.type" :label="0">全部</el-radio>
          </div> -->
        </div>
        <div class="type">
          <p>
            <span>订单编号:</span>
            <el-input
              placeholder="请输入订单编号"
              size="small"
              v-model="form.order_no"
              clearable
            >
            </el-input>
          </p>
          <p>
            <span>门店ID:</span>
            <el-input
              placeholder="请输入门店ID"
              size="small"
              v-model="form.store_id"
              clearable
            >
            </el-input>
          </p>
           <p>
            <span>退款编号:</span>
            <el-input
              placeholder="请输入退款编号"
              size="small"
              v-model="form.order_refund_no"
              clearable
            >
            </el-input>
          </p>
          <!-- <p>
            <span>退货单号:</span>
            <el-input
              placeholder="请输入退货单号"
              size="small"
              v-model="form.order_refund_no"
              clearable
            >
            </el-input>
          </p> -->
          <p>
            <span>门店名称:</span>
            <el-input
              placeholder="请输入门店名称"
              size="small"
              v-model="form.store_name"
              clearable
            >
            </el-input>
          </p>
          <p>
            <span>售后类型:</span>
           <el-select v-model="form.status" placeholder="请选择售后类型">
              <el-option
                v-for="item in statusList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </p>
          <p>
            <span>订单类型:</span>
            <el-select v-model="form.order_type" placeholder="请选择订单类型">
              <el-option
                v-for="item in order_typeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </p>
        </div>
        <el-button type="primary" plain size="small" @click="search"
          >点击筛选</el-button
        >
        <span
          class="addshop shou"
          style="
            background-color: #fc5531;
            padding: 5px 10px;
            margin-left: 10px;
            color: #fff;
            border-radius: 4px;
          "
          @click="exportShop"
          >导出订单 <i class="el-icon-folder-opened"></i
        ></span>
      </div>
      <el-table style="width: 95%" :data="list" border>
        <el-table-column type="expand">
          <div slot-scope="scope" class="orderbox">
            <div class="orderGoods cleafix">
              <h4>订单商品</h4>
              <div
                class="goodsDetail"
                v-for="item in scope.row.order.order_detail"
                :key="item.id"
              >
                <div class="img">
                  <img :src="imgurl + item.pic" alt="" />
                </div>
                <div class="data">
                  <p class="sheng">{{ item.goods_name }}</p>
                  <p class="sheng">
                    规格:
                    <span v-if="item.attr != ''" style="margin-right: 20px">{{
                      item.attr
                    }}</span>
                    <span v-else style="margin-right: 20px">默认</span>
                    数量:<span>{{ item.num }}</span>
                  </p>
                  <p class="sheng">
                    小计: <span>{{ item.total_price }}</span
                    >元
                  </p>
                </div>
              </div>
            </div>
            <div class="order">
              <p>
                用户:<span>{{ scope.row.store_user.username }}</span>
              </p>
              <p>
                订单编号:<span>{{ scope.row.order.order_no }}</span>
              </p>
              <p>
                实付金额:<span>{{ scope.row.order.pay_price }}</span
                >元
              </p>
              <p>
                总金额:<span>{{ scope.row.order.total_price }}</span
                >元
              </p>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="ID" prop="id"> </el-table-column>
        <el-table-column label="门店ID" prop="store_id"> </el-table-column>
        <el-table-column label="订单编号">
          <template slot-scope="scope">
            {{scope.row.order.order_no}}
          </template>
        </el-table-column>
         <el-table-column label="退款编号" prop="order_refund_no">
        </el-table-column>
        <el-table-column label="所属门店">
          <div slot-scope="scope">
            {{ scope.row.store.name }}
          </div>
        </el-table-column>
        <el-table-column label="申请时间" prop="addtime">
          <div slot-scope="scope">
            {{ scope.row.addtime | datefilter }}
          </div>
        </el-table-column>
        <el-table-column label="退货金额" prop="refund_price">
        </el-table-column>
        <el-table-column label="售后类型" width="200">
          <el-tag size="mini" type="warning" effect="dark">退货</el-tag>
        </el-table-column>
        <!-- <el-table-column label="申请理由" prop="desc"></el-table-column> -->
        <el-table-column label="商家操作时间">
          <div slot-scope="scope">
            <span v-if="scope.row.response_time != 0">{{
              scope.row.response_time | datefilter
            }}</span>
            <span v-else>暂未处理</span>
          </div>
        </el-table-column>
        <el-table-column label="进度" prop="status">
          <div slot-scope="scope">
            <el-tag
              v-if="scope.row.status == 1"
              size="mini"
              type="success"
              effect="dark"
              >已处理</el-tag
            >
            <el-tag
              v-else-if="scope.row.status == 0"
              size="mini"
              type="info"
              effect="dark"
              >未处理</el-tag
            >
            <el-tag v-else size="mini" type="danger" effect="dark"
              >已拒绝</el-tag
            >
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="CurrentChange"
        :current-page="form.page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShows">取 消</el-button>
        <el-button type="primary" @click="exportShop2">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { refundOrderList } from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
import { filenameFormat } from "@/utils/index";
export default {
  components: {},
  name: "orderAfter",
  data() {
    return {
      imgurl: "",
      form: {
        order_no: null,
        start_time: null,
        end_time: null,
        refund_start_time: null,
        refund_end_time: null,
        page: 1,
        order_refund_no: null,
        store_name: "",
        apply_start_time: null,
        apply_end_time: null,
        status: null, //售后类型
        order_type: null, //订单类型
        store_id: '',
      },
      total: 1,
      TimeValue: null,
	    applyTime: null,
      startTime: null,
      list: [],
      exportShow: false,
      exportType: 1,
      statusList: [{
        id: 0,
        name: '待商家处理'
      },{
        id: 1,
        name: '同意退款'
      },{
        id: 2,
        name: '已拒绝退款'
      }],
      order_typeList: [{
          id: 1,
          name: '自提订单'
        },{
          id: 2,
          name: '物流订单'
        }],
    };
  },
  methods: {
    // 导出订单
    exportShop() {
      this.exportShow = true;
    },
    // 确认导出
    exportShop2() {
      this.exportShow = false;
      this.form.export = "export";
      this.form.export_type = this.exportType;
      this.getlist();
      this.form.export = "";
    },
    // 取消导出
    exportShows() {
      this.exportShow = false;
    },
    CurrentChange(page) {
      this.form.page = page;
      this.getlist();
    },
    async getlist() {
      if (this.form.export) {
        let { data, types, request } = await refundOrderList(this.form, "blob");
        let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
        console.log(request.getAllResponseHeaders());
        // decodeURI(request.getResponseHeader("content-disposition").split('=')[1])
        let filename = decodeURI(
          request.getResponseHeader("content-disposition")
        );
        let objectUrl = URL.createObjectURL(blob); //生成下载链接
        let a = document.createElement("a"); //创建a标签用于文件下载
        a.href = objectUrl; //赋值下载路径
        a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
        ); //下载的文件名称（非必填）
        document.body.appendChild(a); //插入DOM树
        a.click(); //点击a标签触发
        document.body.removeChild(a); //删除a标签
      } else {
        console.log("2");
        const { data } = await refundOrderList(this.form);
        if (data.code != 200) return this.$message.error(data.data);
        this.list = data.data.order_refund_list.data;
        this.total = data.data.order_refund_list.total;
      }
    },
    search() {

      if (this.TimeValue != null) {
        this.form.start_time = this.$timeTo.time4(this.TimeValue[0]);
        this.form.end_time = this.$timeTo.time4(this.TimeValue[1]);
      } else {
        this.form.start_time = "";
        this.form.end_time = "";
      }
      if (this.applyTime != null) {
        this.form.refund_start_time = this.$timeTo.time4(this.applyTime[0]);
        this.form.refund_end_time = this.$timeTo.time4(this.applyTime[1]);
      } else {
        this.form.refund_start_time = "";
        this.form.refund_end_time = "";
      }
      if (this.startTime != null) {
        this.form.apply_start_time = this.$timeTo.time4(this.startTime[0]);
        this.form.apply_end_time = this.$timeTo.time4(this.startTime[1]);
      } else {
        this.form.apply_start_time = "";
        this.form.apply_end_time = "";
      }
      this.form.page = 1;
      this.getlist();
    },
    setOrderType(e) {
      this.orderItem = e;
    },
  },
  created() {
    this.getlist();
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
  },
};
</script>
<style scoped lang='less'>
h4 {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 2px;
}
.orderGoods {
  width: 40%;
}
.img {
  width: 50px;
  float: left;
}
.img img {
  width: 100%;
}
.applyTime{
	margin-left: 5px;
  font-size: 14px;
}
.goodsDetail {
  overflow: hidden;
  padding: 15px 10px;
  margin-bottom: 5px;
}
.box {
  /deep/.orderbox {
    border: 1px solid #999;
    display: flex;
    margin: 5px;
    align-items: center;
    .order {
      padding: 10px 20px 5px 20px;
      p {
        margin-right: 10px;
        font-size: 16px;
        margin-bottom: 5px;
        span {
          margin-left: 5px;
        }
      }
    }
    /deep/.orderGoods {
      border-right: 1px solid #999;
      padding: 10px 20px;
      width: 600px;
      h4 {
        font-weight: normal;
        font-size: 16px;
        margin-bottom: 2px;
      }
      .goodsDetail {
        border: 1px solid #333;
        &:hover {
          border: 1px solid #aaa;
        }
        width: 500px;
        margin-right: 10px;
        border: 1px solid #eee;
        padding: 15px 10px;
        margin-bottom: 5px;
        display: flex;
        .img {
          width: 50px;
          height: 50px;
          background-color: #e9ebec;
          margin-right: 10px;
          img {
            width: 100%;
          }
        }
        .data {
          p {
            font-size: 12px;
            &:nth-child(2) {
              margin: 5px 0;
            }
            span {
              color: #db5376;
            }
          }
        }
      }
    }
  }

  .orderstatus {
    .el-tag {
      margin: 0 10px 2px 0;
    }
  }
  .condition {
    height: 150px;
    background: #f1f3f4;
    padding: 10px;
    margin: 20px 0;
    .orderTime {
      display: inline-block;
      font-size: 14px;
    }
    .type {
      display: flex;
      padding: 10px 0;
      .el-input {
        width: 260px;
      }
      p {
        margin-right: 10px;
        font-size: 14px;
        span {
          display: inline-block;
        }
      }
    }
  }
}
.check {
  display: inline-block;
  margin-left: 30px;
  font-size: 14px;
  span {
    margin-right: 10px;
    display: inline-block;
  }
}
/deep/.warning-row {
  background: oldlace;
}

/deep/.success-row {
  background: #f0f9eb;
}
/deep/.el-table__expanded-cell[class*="cell"] {
  padding: 0;
}
</style>